<template>
  <el-card style="min-height: 90vh;">
    <div style="font-size: 40px; margin-bottom: 20px;">门票订单</div>
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="orderstime" label="下单日期" width="180"></el-table-column>
        <el-table-column prop="username" label="用户" width="180"></el-table-column>
        <el-table-column prop="attractionsname" label="景区名字"></el-table-column>
        <el-table-column prop="attractionsprice" label="价格"></el-table-column>
        <el-table-column prop="count" label="票数"></el-table-column>
        <el-table-column prop="usetime" label="使用日期"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ statusOptions[scope.row.ordersstatus] }}
          </template>
        </el-table-column>
        <el-table-column label="操作"> 
          <template slot-scope="scope">
            <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next,->,total"
        :current-page="pageInfo.pageNum"
        :total="pageInfo.total"
        :page-size="pageInfo.pageSize"
        @current-change="currentChange"
      ></el-pagination>
      <!-- 编辑状态对话框 -->
      <el-dialog title="编辑状态" :visible.sync="dialogVisible" width="30%">
        <el-form :model="editForm">
          <el-form-item label="状态" :label-width="formLabelWidth">
            <el-select v-model="editForm.ordersstatus" placeholder="请选择">
              <el-option v-for="(value, key) in statusOptions" :key="key" :label="value" :value="key"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateStatus">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>

export default {
  name: 'orders',
  data() {
    return {
      tableData: [],
      statusOptions: {
        1: '待使用',
        2: '已使用',
        3: '已过期',
      },
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 20
      },
      dialogVisible: false,
      editForm: {},
      formLabelWidth: '120px'
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get('/orders/selectPage', {
        params: this.pageInfo
      })
      .then((response) => {
        // console.log(response.data.list);
        this.tableData = response.data.list.map(item => {
          let startTime = item.starttime ? item.starttime : '——';
          let endTime = item.endtime ? item.endtime : '——';
          return {
            ...item,
            usetime: `${startTime}至${endTime}`
          };
        });
        this.pageInfo.total = response.data.total;
      })
      .catch((error) => {
        console.error("获取数据失败:", error);
      });
    },
    handleEdit(row) {
      this.editForm = { ...row };
      this.dialogVisible = true;
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.delete(`/orders/deleteOrder/${row.id}`) 
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getData();
          })
          .catch((error) => {
            this.$message({
              type: 'error',
              message: error,
            });
            // console.error("删除失败:", error);
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });      
      });
    },
    updateStatus() {
      axios.post('/orders/updateOrder', this.editForm)
        .then(() => {
          this.$message({
            type: 'success',
            message: '状态更新成功!'
          });
          this.dialogVisible = false;
          this.getData();
        })
        .catch((error) => {
          this.$message({
            type: 'error',
            message: '状态更新成功!'
          });
          // console.error("状态更新失败:", error);
        });
    },
    currentChange(pageNum) {
      this.pageInfo.pageNum = pageNum;
      this.getData();
    }
  },
};
</script>