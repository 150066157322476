<template>
  <div >
    <h1 style="display: block;margin: 0px auto;text-align: center;padding-top: 130px">石圆景区购票系统</h1>
    <div class="login-container">
      <el-form :model="loginForm" :rules="rules" ref="loginForm" status-icon label-position="left" label-width="0px"
        class="demo-ruleForm login-page">
        <h3 class="title">系统登录</h3>
        <el-form-item prop="username">
          <el-input type="text" v-model="loginForm.username" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" placeholder="密码" show-password></el-input>
        </el-form-item>
        <div class="go-reg">
          <span>还没有账号？去</span>
          <span class="link" @click="goToRegister">注册</span>
        </div>
        <el-form-item style="width: 100%">
          <el-button type="primary" style="width:100%;" @click="handleLogin" :loading="logining">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      logining: false,
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  methods: {
    handleLogin() {
      this.logining = true;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          axios.post('/user/login', this.loginForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.$message.success('登录成功');
                this.$store.dispatch('updateUserData', response.data.data);
                window.localStorage.setItem('token', response.data.data.token);
                if (response.data.data.status === 1) {
                this.$router.replace({ path: '/Tourists' });
              }else if (response.data.data.status === 2) {
                this.$router.replace({ path: '/attractions' });
              }
              } else {
                this.$message.error('登录失败：' + response.data.msg);
              }
              this.logining = false;
            })
            .catch((error) => {
              this.$message.error('登录失败：' + error.msg);
              this.logining = false;
            });
        } else {
          this.$message.error('请填写完整的登录信息');
          this.logining = false;
        }
      });
    },
    goToRegister() {
      this.$router.replace('/register');
    },
  }
};
</script>

<style scoped>
.login-container {
  width: 300px;
  height: fit-content;
  padding: 10px 30px;
  margin: 50px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}

.go-reg {
  text-align: right;
  font-size: 14px;
  color: #606266;
  margin-bottom: 10px;
}

.go-reg .link {
  color: #409EFF;
  text-decoration: underline;
  cursor: pointer;
}

.go-reg .link:hover {
  color: #66b1ff;
}

/deep/ .el-form-item__content {
  line-height: 0px !important;
}
/deep/.el-input__inner {
  background-color: #ffffff00 !important;
  border: 1px solid #000000;
}
</style>