<template>
  <el-card style="min-height: 90vh;">
    <div style="font-size: 40px; margin-bottom: 20px;">门票管理</div>
    <div>
      <el-input style="width: 200px" v-model="input" placeholder="搜索景点名"></el-input>
      <el-button type="primary" @click="searchData">搜索</el-button>
      <el-table :data="tableData" border v-loading="loading" style="width: 100%; margin-top: 20px">
        <!-- <el-table-column type="selection" width="55">
        </el-table-column> -->
        <el-table-column prop="attractionsid" label="景点id" v-if="false"></el-table-column>
        <el-table-column label="图片" width="180">
          <template slot-scope="scope">
            <el-image v-if="scope.row.photo" :src="scope.row.photo">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="attractionsname" label="景点名称" width="180"></el-table-column>
        <el-table-column prop="usetime" label="开放时间" width="280"></el-table-column>
        <el-table-column prop="attractionsprice" label="价格" width="100">
          <template slot-scope="scope">
              {{ scope.row.attractionsprice }}元
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column label="介绍" width="300">
          <template slot-scope="scope">
            <div class="introduction" :title="scope.row.content">
              {{ scope.row.content }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="detailData(scope.row)">详情</el-button>
            <el-button type="primary" size="small" @click="editData(scope.row)">修改</el-button>
            <el-button type="danger" size="small" @click="deleteData(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next,->,total" :current-page="pageInfo.pageNum"
        :total="pageInfo.total" :page-size="pageInfo.pageSize" @current-change="currentChange"></el-pagination>
      <!-- 新增,修改弹窗 -->
      <el-dialog :title="title" :visible.sync="dialogVisible" @close="handleDialogClose">
        <el-form :model="dataForm">
          <el-form-item label="图片" :label-width="formLabelWidth">
            <el-upload class="avatar-uploader" action="" :auto-upload="false" :show-file-list="false"
              :on-change="handleChangeUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="景点名称" :label-width="formLabelWidth">
            <el-input v-model="dataForm.attractionsname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="修改使用时间" :label-width="formLabelWidth">
            <el-row>
              <el-col :span="12">
                <el-date-picker v-model="dataForm.startDate" type="datetime" :placeholder="startDateplaceholder"
                  format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-col>
              <!-- <el-col :span="12">
                <el-time-picker v-model="dataForm.startTime" placeholder="选择开始时间" format="HH:mm" value-format="HH:mm" >
                </el-time-picker>
              </el-col> -->
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-date-picker v-model="dataForm.endDate" type="datetime" :placeholder="endDateplaceholder"
                  format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-col>
              <!-- <el-col :span="12">
                <el-time-picker v-model="dataForm.endTime" placeholder="选择结束时间" format="HH:mm" value-format="HH:mm" >
                </el-time-picker>
              </el-col> -->
            </el-row>
          </el-form-item>
          <el-form-item label="价格" :label-width="formLabelWidth">
            <el-input v-model="dataForm.attractionsprice" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="地址" :label-width="formLabelWidth">
            <el-input v-model="dataForm.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="介绍" :label-width="formLabelWidth">
            <el-input v-model="dataForm.content" autocomplete="off" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveData">确 定</el-button>
        </div>
      </el-dialog>
      <!-- vueCropper 剪裁图片实现 -->
      <el-dialog title="图片剪裁" :visible.sync="cropDialogVisible" class="crop-dialog" append-to-body>
        <div class="cropper-content">
          <div class="cropper" style="text-align:center">
            <vue-cropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType"
              :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox"
              :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed"
              :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue"
              :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight"
              @cropMoving="cropMoving" />
          </div>
        </div>
        <div class="action-box">
          <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
          <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
          <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
          <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
          <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cropDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="finish">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </el-card>
</template>

<script>
import 'cropperjs/dist/cropper.css';
export default {
  data() {
    return {
      input: '',
      title: '',
      tableData: [],
      dataTime: '',
      startDateplaceholder: '',
      endDateplaceholder: '',
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 6
      },
      dialogVisible: false,
      cropDialogVisible: false,
      // isFormSubmitted: false, // 添加一个标志位来跟踪表单是否已提交
      dataForm: {
        photo: '',
        attractionsname: '',
        startDate: '',
        endDate: '',
        attractionsprice: '',
        address: '',
        content: ''
      },
      formLabelWidth: '120px',
      loading: true,
      fileList: [],
      imageUrl: '',
      isPreview: false,
      editForm: {},
      previewImg: '', // 预览图片地址
      option: {
        img: '', // 裁剪图片的地址
        size: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true,// 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [16, 9], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      extension: ''
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get('/attraction/selectPage', {
        params: this.pageInfo
      })
        .then((response) => {
          this.tableData = response.data.list.map(item => {
            let startTime = item.starttime ? item.starttime : '——';
            let endTime = item.endtime ? item.endtime : '——';
            item.usetime = startTime + ' - ' + endTime;
            return item;
          });
          this.pageInfo.total = response.data.total;
          this.pageInfo.pageNum = response.data.pageNum;
          this.pageInfo.pageSize = response.data.pageSize;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    currentChange(pageNum) {
      this.pageInfo.pageNum = pageNum;
      this.getData();
    },
    editData(row) {
      this.dialogVisible = true;
      this.title = '编辑景点';
      this.imageUrl = row.photo;
      this.startDateplaceholder = row.starttime;
      this.endDateplaceholder = row.endtime;
      this.dataForm = { ...row };
      // console.log(this.dataForm);
      // this.dataTime =this.dataForm.starttime +'至'+ this.dataForm.endtime;


    },
    handleDialogClose() {
      this.dataForm = {};
      this.dataTime = '';
    },
    saveData() {
      // console.log(this.dataForm.startDate);
      // console.log(this.dataForm.endDate);
      if (this.title === '编辑景点') {
        if (this.dataForm.startDate === undefined || this.dataForm.startDate === null || this.dataForm.startDate === '') {
          this.dataForm.starttime = this.startDateplaceholder;
        } else {
          this.dataForm.starttime = this.dataForm.startDate;
        }
        if (this.dataForm.endDate == undefined || this.dataForm.endDate === null || this.dataForm.endDate === '') {
          this.dataForm.endtime = this.endDateplaceholder;
        } else {
          this.dataForm.endtime = this.dataForm.endDate;
        }
        // console.log(this.dataForm);
        axios.post('/attraction/update', this.dataForm)
          .then((response) => {
            this.dialogVisible = false;
            this.getData();
            this.$message.success('更新成功');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteData(row) {
      axios.post('/attraction/deletes', [row.attractionsid])
        .then((response) => {
          this.getData();
          this.$message.success('删除成功');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchData() {
      // 实现搜索功能
      this.pageInfo.pageNum = 1;
      axios.get('/attraction/selectPage', {
        params: {
          attractionsname: this.input,
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize
        }
      })
        .then((response) => {
          this.tableData = response.data.list.map(item => {
            let startTime = item.starttime ? item.starttime : '——';
            let endTime = item.endtime ? item.endtime : '——';
            item.usetime = startTime + ' - ' + endTime;
            return item;
          });
          this.pageInfo.total = response.data.total;
          this.pageInfo.pageNum = response.data.pageNum;
          this.pageInfo.pageSize = response.data.pageSize;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    detailData(row) {
      this.$router.push({ name: 'AttractionDetail', params: { attractionsid: row.attractionsid } });
      // console.log(row.attractionsid);
    },
    handleChangeUpload(file, fileList) {
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false;
      }
      this.extension = file.raw.name.split('.').pop().toLowerCase();
      this.option.img = URL.createObjectURL(file.raw);
      this.cropDialogVisible = true;
    },
    changeScaleHandle(num) {
      this.$refs.cropper.changeScale(num);
    },
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = downImg;
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = data;
          aLink.href = downImg;
          aLink.click();
        });
      }
    },
    clearImgHandle() {
      this.option.img = '';
    },
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      // console.log(cropAxis)
    },
    finish() {
      this.$refs.cropper.getCropBlob((blob) => {
        this.loading = true;
        this.cropDialogVisible = false;
        this.previewImg = URL.createObjectURL(blob);
        this.isPreview = true;
        this.uploadImage(blob);
      });
    },
    uploadImage(blob) {
      let formData = new FormData();
      formData.append('file', blob, `attractions.${this.extension}`); // 你可以根据实际情况修改文件名
      axios.post('/OSS/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          // console.log('上传成功', response);
          this.imageUrl = response.data; // 假设返回的数据中包含图片的URL
          this.dataForm.photo = response.data;
          console.log(this.imageUrl);
          this.loading = false;
        })
        .catch(error => {
          console.error('上传失败', error);
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.introduction {
  max-height: 120px; /* 根据需要调整高度 */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2; /* 显示两行文本 */
  -webkit-box-orient: vertical;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 2px dashed #d9d9d9;
}

.avatar {
  height: 178px;
  display: block;
}

.cropper {
  width: auto;
  height: 350px;
}
</style>
