<template>
  <div class="user-card" >
    <el-card class="user-header">
      <div style="font-size: 40px; margin-bottom: 20px;">当前管理员账户</div>
      <div class="user-avatar">
        <el-image :src="userData.photo" :preview-src-list="srcList" class="admin-image" ></el-image>
      </div>
      <hr />
      <div class="user-name">{{ userData.username }}</div>
    </el-card>
    <el-tabs type="border-card">
      <el-tab-pane label="管理员账户">
        <el-button type="primary" style="margin-left:20px;" @click="addAdmin">添加管理员</el-button>
        <el-table style="width: 100%;" :data="admins">
          <el-table-column prop="userid" label="用户ID"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="photo" label="头像">
            <template slot-scope="scope">
              <el-image :src="scope.row.photo" class="avatar-image"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="editAdmin(scope.row)">编辑</el-button>
              <el-button type="danger" @click="deleteAdmin(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next, jumper, ->, total" :current-page="adminPageInfo.pageNum"
          :total="adminPageInfo.total" :page-size="adminPageInfo.pageSize"
          @current-change="handlePageChange1"></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="游客账户">
        <el-button type="primary" style="margin-left:20px;" @click="addAdmin">添加游客</el-button>
        <el-table style="width: 100%;" :data="users">
          <el-table-column prop="userid" label="用户ID"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="photo" label="头像">
            <template slot-scope="scope">
              <el-image :src="scope.row.photo" class="avatar-image"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="editAdmin(scope.row)">编辑</el-button>
              <el-button type="danger" @click="deleteAdmin(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next, jumper, ->, total" :current-page="userPageInfo.pageNum"
          :total="userPageInfo.total" :page-size="userPageInfo.pageSize"
          @current-change="handlePageChange2"></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 添加管理员对话框 -->
    <el-dialog title="添加管理员" :visible.sync="addAdminDialogVisible" width="550px">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        label-position="left">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="ruleForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input type="password" v-model="ruleForm.checkPassword" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm" style="width:100%;">申请注册</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑管理员对话框 -->
    <el-dialog title="编辑管理员" :visible.sync="editAdminDialogVisible" width="550px">
      <el-form :model="editForm" status-icon :rules="rules" ref="editForm" label-width="100px" class="demo-ruleForm"
        label-position="left">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="editForm.password" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload class="avatar-uploader" action="" :auto-upload="false"
            :show-file-list="false" :on-change="handleChangeUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAdminDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAdmin">确 定</el-button>
      </span>
    </el-dialog>
    <!-- vueCropper 剪裁图片实现 -->
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vue-cropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType"
            :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox"
            :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed"
            :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight"
            @cropMoving="cropMoving" />
        </div>
      </div>
      <div class="action-box">
        <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
        <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
        <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
        <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
        <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'Usercenter',
  data() {
    var validateName = (rule, value, callback) => {
      const pattern = /^[a-zA-Z_][0-9a-zA-Z_-]*$/;
      if (value === '') {
        callback(new Error('请输入用户名'));
      } else if (!pattern.test(value)) {
        callback(new Error('只能包含字母、数字、下划线、减号，且不能以数字开头'));
      } else if (value.length < 6) {
        callback(new Error('用户名不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('用户名不能大于15位'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      const pattern = /^[a-zA-Z_][0-9a-zA-Z_-]*$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!pattern.test(value)) {
        callback(new Error('密码只能包含字母、数字、下划线、减号'));
      } else if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('密码不能大于15位'));
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      admins: [],
      users: [],
      extension: '',
      addAdminDialogVisible: false,
      editAdminDialogVisible: false,
      dialogVisible: false,
      srcList: [],
      adminPageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 3
      },
      userPageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 3
      },
      editForm: {
      },
      ruleForm: {
        password: '',
        checkPassword: '',
        username: '',
        status: 2,
        createtime: ''
      },
      rules: {
        username: [
          { required: true, validator: validateName, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
      isPreview: false,
      imageUrl: '',
      extension: '',
      previewImg: '', // 预览图片地址
      option: {
        img: '', // 裁剪图片的地址
        size: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      loading: false,
      currentAdminId: ''
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    this.getadmins();
    this.getusers();
    // console.log(this.userData);
    this.currentAdminId = this.userData.userid; // 从Vuex中获取当前用户ID
    this.srcList.push(this.userData.photo);
  },
  methods: {
    async getadmins() {
      try {
        const response = await axios.post('/user/selectByStatus', {
          status: 2,
          pageNum: this.adminPageInfo.pageNum,
          pageSize: this.adminPageInfo.pageSize
        });
        this.admins = response.data.data.list;
        this.adminPageInfo.total = response.data.data.total;
        this.adminPageInfo.pageNum = response.data.data.pageNum;
        this.adminPageInfo.pageSize = response.data.data.pageSize;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          //
        }
        console.error('获取管理员数据失败', error);
      }
    },

    async getusers() {
      try {
        const response = await axios.post('/user/selectByStatus', {
          status: 1,
          pageNum: this.userPageInfo.pageNum,
          pageSize: this.userPageInfo.pageSize
        });
        // console.log(response.data);
        this.users = response.data.data.list;
        this.userPageInfo.total = response.data.data.total;
        this.userPageInfo.pageNum = response.data.data.pageNum;
        this.userPageInfo.pageSize = response.data.data.pageSize;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          //
        }
        console.error('获取游客数据失败', error);
      }
    },
    addAdmin() {
      this.addAdminDialogVisible = true;
    },
    async submitForm() {
      // 获取当前的时间
      const currentTime = new Date();
      const year = currentTime.getFullYear();
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // 确保月份、日期、小时、分钟和秒钟是两位数
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      const formattedTime = `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

      this.ruleForm.createtime = formattedTime;
      // console.log(this.ruleForm);

      try {
        await axios.post('/user/add', this.ruleForm)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message.success('注册成功');
              this.ruleForm = {
                password: '',
                checkPassword: '',
                username: '',
                status: ""
              };
              this.getadmins();
              this.addAdminDialogVisible = false;
            } else if (response.data.msg === "用户名已存在") {
              this.$message.error('注册失败');
            }
          })
      } catch (error) {
        console.error('提交表单失败', error);
      }
    },
    handleChangeUpload(file, fileList) {
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false;
      }
      this.extension = file.raw.name.split('.').pop().toLowerCase();
      this.option.img = URL.createObjectURL(file.raw);
      this.dialogVisible = true;
    },
    changeScaleHandle(num) {
      this.$refs.cropper.changeScale(num);
    },
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = downImg;
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = data;
          aLink.href = downImg;
          aLink.click();
        });
      }
    },
    clearImgHandle() {
      this.option.img = '';
    },
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      // console.log(cropAxis)
    },
    finish() {
      this.$refs.cropper.getCropBlob((blob) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = URL.createObjectURL(blob);
        this.isPreview = true;
        this.uploadImage(blob);
      });
    },
    uploadImage(blob) {
      let formData = new FormData();
      formData.append('file', blob, `user.${this.extension}`); // 你可以根据实际情况修改文件名

      axios.post('/OSS/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          // console.log('上传成功', response);
          this.imageUrl = response.data; // 假设返回的数据中包含图片的URL
          this.editForm.photo = response.data;
          // console.log(this.imageUrl);
          this.loading = false;
        })
        .catch(error => {
          console.error('上传失败', error);
          this.loading = false;
        });
    },
    editAdmin(row) {
      console.log(row);
      this.editForm = { ...row };
      this.imageUrl = row.photo;
      this.editAdminDialogVisible = true;
    },
    updateAdmin() {
      // console.log(this.editForm);
      // 更新用户信息
      axios.post('/user/update', this.editForm)
        .then(response => {
          if (response.data.code === 200) {
            this.$message.success('用户信息更新成功');
            this.editAdminDialogVisible = false;
            if (this.editForm.username === this.userData.username) {
              // 更新 Vuex store
              this.$store.commit('setUserData', this.editForm);
              this.srcList.push(this.userData.photo);
            }
            this.editForm = {};
            this.getadmins();
          }
        })
        .catch(error => {
          this.$message.error('更新失败：' + error.message);
        });
    },
    deleteAdmin(row) {
      if (row.userid === this.currentAdminId) {
        this.$message.warning('不能删除当前登录的管理员');

        return;
      }
      this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发送删除请求
        axios.post('/user/delete', { userid: row.userid })
          .then(response => {
            if (response.data.code === 200) {
              this.$message.success('删除成功');
              this.getadmins();
              this.getusers();
            }
          })
          .catch(error => {
            this.$message.error('删除失败：' + error.message);
          });
      }).catch(() => {
        // 取消操作，不执行任何代码
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handlePageChange1(newPage) {
      this.adminPageInfo.pageNum = newPage;
      this.getadmins(); // 重新获取数据
    },
    handlePageChange2(newPage) {
      this.userPageInfo.pageNum = newPage;
      this.getusers(); // 重新获取数据
    },
  }
}
</script>

<style scoped>
.user-header {
  margin-bottom: 20px;
}
.user-avatar {
  width: 100%;
}
.admin-image {
  width: 250px;
  margin: 20px;
  z-index: 999;
  border-radius:5% ;
  border: 1px solid #181313;
}

.user-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 10px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 2px dashed #d9d9d9;
}

.avatar-image {
  width: 100px;
  height: 100px;
}
.avatar{
  width: 350px;
  height: 350px;
}
.cropper {
  width: auto;
  height: 350px;
}
</style>
