<template>
  <el-container class="home_box">
    <el-header class="home_header" height="65px">
      <div style="font-size: 25px;"><img src="@/assets/shiyuan.png" class="shiyuan" />石圆景区购票系统-{{login}}</div>
      <!-- 使用 el-menu 替换 router-link -->
      <el-menu
      v-if="showTouristItems"
        :default-active="activeIndex"

        background-color="#B3C0D100"
        text-color="#000000"
        active-text-color="#41adb4"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="Tourists">购票大厅</el-menu-item>
        <el-menu-item index="History">购票记录</el-menu-item>
        <el-menu-item index="Usercenter">个人中心</el-menu-item>
      </el-menu>
      <div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i v-if="userData" class="el-icon-arrow-down el-icon--right">{{ userData.username }}</i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">退出登陆</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px" v-if="showAdminItems">
        <el-menu
          background-color="#ffffff00"
          router
          :default-active="$route.path"
          active-text-color="#ffd04b"
          min-height="100vh"
        >
          <el-menu-item index="attractions" >
            <i class="el-icon-document"></i>门票管理
          </el-menu-item>
          <el-menu-item index="addattractions" >
            <i class="el-icon-document"></i>添加景点门票
          </el-menu-item>
          <el-menu-item index="orders" >
            <i class="el-icon-tickets"></i>门票订单
          </el-menu-item>
          <el-menu-item index="admincenter" >
            <i class="el-icon-tickets"></i>管理员账户
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      login: null,
      showTouristItems: false,
      showAdminItems: false,
      activeIndex: '' // 默认激活的菜单项
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    },
  },
  mounted() {
    // console.log( this.userData);
    if (this.userData != null) {
      if (this.userData.status == 1) {
        this.showTouristItems = true;
        this.login = '游客端';
      } else if (this.userData.status == 2) {
        this.showAdminItems = true;
        this.login = '景区端';
      }
    } else {
      this.$message.error('请先登录！');
      this.$router.replace({ path: '/login' });
    }
    this.activeIndex = this.$route.path.replace('/', '');
  },
  methods: {
    handleSelect(key, keyPath) {
  // 处理菜单选择事件
  switch (key) {
    case 'Tourists':
      if (this.$route.path !== '/Tourists') {
        this.$router.push('/Tourists');
      }
      break;
    case 'History':
      if (this.$route.path !== '/History') {
        this.$router.push('/History');
      }
      break;
    case 'Usercenter':
      if (this.$route.path !== '/Usercenter') {
        this.$router.push('/Usercenter');
      }
      break;
    default:
      // 可以添加默认情况的处理逻辑
      break;
  }
},
    logout() {
      this.$confirm('注销登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        this.$router.replace({ path: '/login' });
        localStorage.removeItem('loginVlaue');
        this.$store.commit('setUserData', null); // Clear user data in Vuex
        window.localStorage.removeItem("token"); // Clear token from local storage
      })
      .catch(() => {});
    }
  }
}
</script>

<style>
.home_box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.home_header {
  display: flex;
  width: auto;
  width: 100%;
  min-width: 1530px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; /* Add some padding */
  height: 65px; /* Set a fixed height */
}
.shiyuan {
  margin-right: 20px;
  width: 30px;
  height: auto;
}
.el-menu--horizontal {
  display: flex;
  align-items: center;
  border-bottom: none; /* Remove the bottom border if not needed */
}
.el-menu--horizontal > .el-menu-item {
  margin: 0 10px; /* Adjust the margin as needed */
}

.el-header {
  background-color: #aac3e4;
  color: #333;
  text-align: center;
  line-height: 65px; /* Set the same line-height as .home_header */
}
.el-header :hover {
  cursor: default;
}
.el-aside {
  background-color: #b5c9df;
  color: #333;
  text-align: left;
  line-height: 100vh;
}
.el-main {
  min-width: 1530px;
  background-color: #e8f3f3;
}
</style>