<template>
  <el-card style="min-height: 90vh;">
    <div style="font-size: 40px; margin-bottom: 20px;">购票记录</div>
    <div>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderstime" label="下单日期" width="180"></el-table-column>
        <el-table-column prop="username" label="用户" width="180"></el-table-column>
        <el-table-column prop="attractionsname" label="景区名字"></el-table-column>
        <el-table-column prop="attractionsprice" label="价格"></el-table-column>
        <el-table-column prop="count" label="票数"></el-table-column>
        <el-table-column prop="usetime" label="使用日期" width="250"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ statusOptions[scope.row.ordersstatus] }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next,->,total"
        :current-page="pageInfo.pageNum"
        :total="pageInfo.total"
        :page-size="pageInfo.pageSize"
        @current-change="currentChange"
      ></el-pagination>
    </div>
  </el-card>
</template>

<script>

export default {
  name: 'history',
  data() {
    return {
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10
      },
      statusOptions: {
        1: '待使用',
        2: '已使用',
        3: '已过期',
      },
      dialogVisible: false,
      editForm: {},
      formLabelWidth: '120px'
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get('/orders/selectPage', {
        params: {
          userid: this.userData.userid,
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize
        }
      })
      .then((response) => {
        // console.log(response.data.list);
        this.tableData = response.data.list.map(item => {
          let startTime = item.starttime ? item.starttime : '——';
          let endTime = item.endtime ? item.endtime : '——';
          return {
            ...item,
            usetime: `${startTime}至${endTime}`
          };
        });
        this.pageInfo.total = response.data.total;
      })
      .catch((error) => {
        console.error("获取数据失败:", error);
      });
    },
    handleEdit(row) {
      this.editForm = { ...row };
      this.dialogVisible = true;
    },
    currentChange(currentPage) {
      this.pageInfo.pageNum = currentPage;
      this.getData();
    }
  },
};
</script>