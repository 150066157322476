import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: null
  },
  getters: {
    getUserData: state => state.userData
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    }
  },
  actions: {
    updateUserData({ commit }, userData) {
      commit('setUserData', userData);
    }
  },
  plugins: [
    createPersistedState({
      // 存储到 localStorage
      storage: window.localStorage,
      // 也可以选择 sessionStorage
      // storage: window.sessionStorage
    })
  ]
});