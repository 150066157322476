"use strict";

import Vue from 'vue';
import axios from "axios";
import router from "../router/index"; // 确保正确导入router
import { Message } from 'element-ui';

let config = {
  baseURL: "http://118.31.19.61:8090",
  timeout: 60 * 1000, // Timeout
  Headers: {
    "Content-Type": "application/json",
  }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    if (window.localStorage.getItem("token")) {
      config.headers.Authorization = "Bearer " + window.localStorage.getItem("token");
    }
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    return response;
  },
  function(error) {
    // 对响应错误做点什么
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem("token");
      // console.log(error.response.data);
      if (error.response.data.msg === "token不存在或格式不正确") {
        Message.error("请先登录");
        // console.error('Request error:', error.response.data.msg || 'Unauthorized');
      }else {
        Message.error("登录已过期，请重新登录");
        // console.error('Request error:', error.response.data.msg || 'Unauthorized');
      }
      // 处理401错误，跳转到登录页面
      if (router.currentRoute.path !== '/login') {
        router.replace({ path: '/login' }); // 只有在当前路径不是 /login 时才跳转
      }
    } else {
      // console.error('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

const Plugin = {
  install: function(Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return _axios;
        }
      },
      $axios: {
        get() {
          return _axios;
        }
      },
    });
  }
};

Vue.use(Plugin);

export default Plugin;