import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Tourister from '../views/Tourister.vue'
import Attractions from '../views/Attractions.vue'
import Addattractions from '../views/Addattractions.vue'
import Orders from '../views/Orders.vue'
import History from '../views/History.vue'
import Usercenter from '../views/Usercenter.vue'
import AttractionDetail from '@/views/AttractionDetail.vue';
import Admincenter from '@/views/Admincenter.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: 'tourists',
        name: '主页',
        component: Tourister,
      },
      {
        path: 'history/',
        name: '购票记录',
        component: History,
      },
      {
        path: 'Usercenter/',
        name: '个人中心',
        component: Usercenter,
      },
      
      {
        path: 'attractions',
        name: '景点',
        component: Attractions,
      },
      {
        path: 'addattractions',
        name: '景点',
        component: Addattractions,
      },
      {
        path: 'orders',
        name: '订单',
        component: Orders,
      },
      {
        path: 'admincenter',
        name: '管理员账户',
        component: Admincenter,
      },

    ],
  },
  {
    path: '/attraction-detail/',
    name: 'AttractionDetail',
    component: AttractionDetail,
  },

]



const router = new VueRouter({
  routes
})

router.afterEach((to,from,next)=>{
  window,scrollTo(0,0)
})



export default router
