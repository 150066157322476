import { render, staticRenderFns } from "./Addattractions.vue?vue&type=template&id=23c60fd6&scoped=true"
import script from "./Addattractions.vue?vue&type=script&lang=js"
export * from "./Addattractions.vue?vue&type=script&lang=js"
import style0 from "./Addattractions.vue?vue&type=style&index=0&id=23c60fd6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c60fd6",
  null
  
)

export default component.exports