<template>
  <div style="min-width: 460px">
    <el-card v-model="attraction">
      <div slot="header" class="clearfix">
        <el-page-header @back="back" content="详情页面">
        </el-page-header>
      </div>
      <el-row>
        <el-col :span="16"
          style="min-height: 300px; width: 40%; min-width:235px; margin-right: 30px">
          <el-image v-if="attraction.photo" :src="(attraction.photo)"
            style="object-fit: cover; height: 100%; width: 100%;"></el-image>
        </el-col>
        <el-col :span="8"
          style="text-align: left; display: flex; flex-direction: column; justify-content: space-between; height: 300px;">
          <div style="font-size: 50px; font-weight: bold; margin-bottom: 10px">{{ attraction.attractionsname }}</div>
          <el-tooltip class="item" effect="dark" content="价格" placement="top" >
            <el-tag color="#ffffff00">价格：{{ attraction.attractionsprice || '————' }}</el-tag>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="使用时间" placement="top">
            <el-tag color="#ffffff00">使用时间：{{ attraction.usetime || '————' }}</el-tag>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="地址" placement="top">
            <el-tag color="#ffffff00" >地址：{{ attraction.address || '————' }}</el-tag>
          </el-tooltip>
          <el-button type="danger" style="margin:10px; width: 100px" @click="dialogVisible = true"
            :disabled="userData.status === 2" v-bind:title="userData.status === 2 ? '管理员不能购票' : ''">
            购票
          </el-button>
        </el-col>
      </el-row>
      <el-tabs type="card" style="min-height: 200px; margin-top: 20px">
        <el-tab-pane label="景点介绍" style="padding-left: 20px;">{{ attraction.content }}</el-tab-pane>
      </el-tabs>
    </el-card>
    <el-card style="margin-top: 20px">
      <el-row style="margin-bottom: 20px; ">
        <input type="text" class="contentinput" v-model="input" placeholder="请输入评论内容">
        <el-button type="primary" style="margin-left: 20px; width: 100px;" @click="publish">发表评论</el-button>
      </el-row>
      <el-empty v-if="commentData.length === 0" description="暂无评论"></el-empty>
      <el-row v-for="comment in commentData" :key="comment.commentid">
        <el-col style="margin-left: 20px">
          <el-avatar :src="comment.photo" class="avatar"></el-avatar>
          <span class="comment-content">{{ comment.username }}</span>
        </el-col>
        <el-col>
          <div class="comment-text">{{ comment.comments }}</div>
          <div class="comment-time">{{ comment.commenttime }}</div>
        </el-col>
      </el-row>
      <el-pagination @current-change="handlePageChange" :current-page="pageInfo.pageNum" :page-size="pageInfo.pageSize"
        layout="prev, pager, next" :total="pageInfo.total">
      </el-pagination>
    </el-card>
    <!-- 购票弹窗 -->
    <el-dialog title="购票" :visible.sync="dialogVisible" width="30%">
      <div>
        <div class="dialog-content">
          <span>景点名称：</span>
          <span>{{ attraction.attractionsname }}</span>
        </div>
        <div class="dialog-content">
          <span>价格：</span>
          <span>{{ attraction.attractionsprice }}</span>
        </div>
        <div class="dialog-content">
          <span>使用时间：</span>
          <span>{{ attraction.usetime }}</span>
        </div>
        <div class="dialog-content">
          <span>用户名：</span>
          <span>{{ userData.username }}</span>
        </div>
        <div class="dialog-content">
          <span>数量：</span>
          <el-input-number v-model="num" :min="1" :max="4"></el-input-number>
        </div>
        <div class="dialog-content">
          <span>总价：</span>
          <span>{{ attraction.attractionsprice * num }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitOrder">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'AttractionDetail',
  data() {
    return {
      input: '',
      num: 0,
      userdata: {},
      dialogVisible: false,
      attraction: {
        usetime: '',
      },
      commentData: [],
      comments: {
        userid: '',
        comments: '',
        attractionsid: '',
        commenttime: ''
      },
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  created() {
    this.fetchData();
    this.getComments();
    // console.log(this.userData);
  },
  methods: {
    fetchData() {
      // console.log(this.$route.params.attractionsid);
      if (this.$route.params.attractionsid === undefined) {
        if (this.userData.status === 1){
          this.$router.replace({ path: 'tourists' });
        }else if (this.userData.status === 2){
          this.$router.replace({ path: 'attractions' });
        }
      } else {
        const attractionsid = this.$route.params.attractionsid;
        axios.get(`/attraction/selectById`, {
          params: { attractionsid },
        })
          .then((response) => {
            this.attraction = response.data.data[0];
            this.attraction.usetime = this.attraction.starttime + ' 至 ' + this.attraction.endtime;
            // console.log('Attraction data:', this.attraction);
          })
          .catch((error) => {
            // console.error('Error fetching attraction data:', error);
            this.$message.error('无法加载景点数据');
          });
      }
    },
    publish() {
      const userid = this.userData.userid;
      const content = this.input;
      const attractionsid = this.$route.params.attractionsid;
      // 获取当前的时间
      const currentTime = new Date();
      const year = currentTime.getFullYear();
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();

      // 确保月份、日期、小时、分钟和秒钟是两位数
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      const formattedTime = `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

      // 更新 comments 对象
      this.comments.userid = userid;
      this.comments.comments = content;
      this.comments.attractionsid = attractionsid;
      this.comments.commenttime = formattedTime;
      if (this.comments.comments === '') {
        this.$message.error('评论内容不能为空');
      } else {
        // console.log(this.comments);

        axios.post(`/comment/insert`, this.comments)
          .then((response) => {
            // 处理响应
            this.$message.success('评论成功');
            this.input = '';
            this.getComments(); // 重新获取评论列表
          })
          .catch((error) => {
            this.$message.error('评论失败');
            // console.error('Error posting comment:', error);
          });
      }
    },
    back() {
      this.$router.go(-1);
    },
    getComments() {
      axios.get(`/comment/selectPage`, {
        params: {
          attractionsid: this.$route.params.attractionsid
          , pageNum: this.pageInfo.pageNum, pageSize: this.pageInfo.pageSize
        }
        ,
      }).then((response) => {
        this.commentData = response.data.list;
        this.pageInfo.total = response.data.total
        // console.log('Comment data:', this.commentData);
      })
        .catch((error) => {
          // console.error('Error fetching comment data:', error);
        })
    },
    submitOrder() {
      const attractionsid = this.attraction.attractionsid
      const attractionsname = this.attraction.attractionsname
      const count = this.num
      const price = this.attraction.attractionsprice * this.num
      const userid = this.userData.userid
      // 获取当前的时间
      const currentTime = new Date();
      const year = currentTime.getFullYear();
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const seconds = currentTime.getSeconds();
      // 确保月份、日期、小时、分钟和秒钟是两位数
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      const formattedTime = `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      const order = {
        attractionsid,
        attractionsname,
        price,
        count,
        userid,
        orderstime: formattedTime,
        ordersstatus: 1,
      }
      // console.log(order);
      axios.post(`/orders/addOrder`, order)
        .then((response) => {
          // 处理响应
          this.$message.success('下单成功');
          this.dialogVisible = false;
        })
        .catch((error) => {
          this.$message.error('下单失败');
          console.error('Error posting order:', error);
        });
    },
    handleOrder() {
      if (this.num == 0) {
        this.$message.error('请选择数量');
      } else {
        this.dialogVisible = true;
      }
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handlePageChange(newPage) {
      this.pageInfo.pageNum = newPage;
      this.getComments();
    },
  },
};
</script>

<style scoped>
.item {
  width: auto;
}

.contentinput {
  height: 35px;
  width: calc(100% - 140px);
  border: 1px solid #ccc;
}

.avatar {
  margin-right: 10px;
  /* 固定头像和评论之间的距离 */
  vertical-align: middle;
}

.comment-text,
.comment-time {
  margin-left: 70px;
}

.comment-time {
  color: #999;
  /* 设置评论时间的颜色 */
  font-size: 14px;
  /* 设置评论时间的字体大小 */
  margin-top: 5px;
  /* 设置评论时间与评论内容之间的间距 */
}

.dialog-content {
  margin: 20px 0;
}
</style>