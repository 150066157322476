<template>
  <div class="user-card" style="min-height: 90vh;">
    <!-- Element UI 上传图片按钮 -->
    <el-card >
      <div style="font-size: 40px; margin-bottom: 20px;">个人中心</div>
      <div class="user-header">
        <div class="user-avatar">
          <el-image :src="userData.photo" :preview-src-list="srcList" class="avatar-image"></el-image>
          <el-image :src="userData.photo" class="background"></el-image>
        </div>
        <hr style="margin-top: 0;"/>
        <span class="user-name" style="">{{ userData.username }}</span>
      </div>
    </el-card>
    <el-card style="margin-top: 20px;">
      <div>
        <div style="font-size: 40px; margin-bottom: 20px;">修改用户</div>
        <el-collapse v-model="activeNames" class="user-edit-panel" @change="toggleEditPanel" >
          <el-collapse-item title="修改用户名" name="2">
            <el-form ref="usernameForm" :model="usernameForm" :rules="usernameRules" label-width="80px">
              <el-form-item label="新用户名" prop="username">
                <el-input v-model="usernameForm.username"></el-input>
              </el-form-item>
            </el-form>
            <div class="dialog-footer">
              <el-button @click="activeNames = []">取消</el-button>
              <el-button type="primary" @click="submitUsername">确定</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="修改密码" name="3">
            <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules" label-width="80px">
              <el-form-item label="当前密码" prop="currentPassword">
                <el-input type="password" v-model="passwordForm.currentPassword"></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="passwordForm.newPassword"></el-input>
              </el-form-item>
              <el-form-item label="确认新密码" prop="confirmPassword">
                <el-input type="password" v-model="passwordForm.confirmPassword"></el-input>
              </el-form-item>
            </el-form>
            <div class="dialog-footer">
              <el-button @click="activeNames = []">取消</el-button>
              <el-button type="primary" @click="submitPassword">确定</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="修改头像" name="1">
            <el-upload class="avatar-uploader" action="" :auto-upload="false" :show-file-list="false"
              :on-change="handleChangeUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- vueCropper 剪裁图片实现 -->
      <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body>
        <div class="cropper-content">
          <div class="cropper" style="text-align:center">
            <vue-cropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType"
              :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox"
              :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed"
              :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue"
              :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight"
              @cropMoving="cropMoving" />
          </div>
        </div>
        <div class="action-box">
          <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
          <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
          <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
          <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
          <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
// import vueCropper from 'vue-cropper';

export default {
  name: 'Cropper',
  data() {
    return {
      activeNames: [],
      dialogVisible: false,
      editForm: {},
      imageUrl: '',
      extension: '',
      srcList: [],
      previewImg: '', // 预览图片地址
      option: {
        img: '', // 裁剪图片的地址
        size: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      loading: false,
      usernameForm: {
        username: ''
      },
      passwordForm: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      usernameRules: {
        username: [
          { required: true, validator: this.validateName, trigger: 'blur' }
        ]
      },
      passwordRules: {
        currentPassword: [
          { required: true, validator: this.validatePass1, trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: this.validatePass2, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: this.validatePass3, trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      axios.get('/user/selectById', {
        params: { userid: this.userData.userid }
      }).then(response => {
        if (response.data.code === 200) {
          this.$store.commit('setUserData', response.data.data[0]);
          this.srcList.push(this.userData.photo);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    toggleEditPanel(activeNames) {
      if (activeNames.includes('1') || activeNames.includes('2') || activeNames.includes('3')) {
        this.editForm = { ...this.userData };
        // this.imageUrl = this.editForm.photo ? this.editForm.photo : '';
      }
    },
    validateName(rule, value, callback) {
      const pattern = /^[a-zA-Z_][a-zA-Z0-9_-]*$/;
      if (value === '') {
        callback(new Error('请输入用户名'));
      } else if (!pattern.test(value)) {
        callback(new Error('只能包含字母、数字、下划线、减号，且不能以数字开头'));
      } else if (value.length < 6) {
        callback(new Error('用户名不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('用户名不能大于15位'));
      } else {
        callback();
      }
    },
    validatePass1(rule, value, callback) {
      const pattern = /^[a-zA-Z0-9_-]*$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!pattern.test(value)) {
        callback(new Error('密码只能包含字母、数字、下划线、减号'));
      } else if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('密码不能大于15位'));
      } else if (value !== this.userData.password) {
        callback(new Error('当前密码错误'));
      } else {
        callback();
      }
    },
    validatePass2(rule, value, callback) {
      const pattern = /^[a-zA-Z0-9_-]*$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!pattern.test(value)) {
        callback(new Error('密码只能包含字母、数字、下划线、减号'));
      } else if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('密码不能大于15位'));
      } else {
        callback();
      }
    },
    validatePass3(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    handleChangeUpload(file, fileList) {
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false;
      }
      this.extension = file.raw.name.split('.').pop().toLowerCase();
      this.option.img = URL.createObjectURL(file.raw);
      this.dialogVisible = true;
    },
    changeScaleHandle(num) {
      this.$refs.cropper.changeScale(num);
    },
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = downImg;
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = data;
          aLink.href = downImg;
          aLink.click();
        });
      }
    },
    clearImgHandle() {
      this.option.img = '';
    },
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      // console.log(cropAxis)
    },
    finish() {
      this.$refs.cropper.getCropBlob((blob) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = URL.createObjectURL(blob);
        this.isPreview = true;
        this.uploadImage(blob);
      });
    },
    uploadImage(blob) {
      let formData = new FormData();
      formData.append('file', blob, `user.${this.extension}`); // 你可以根据实际情况修改文件名

      axios.post('/OSS/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          // console.log('上传成功', response);
          this.imageUrl = response.data; // 假设返回的数据中包含图片的URL
          this.editForm.photo = response.data;
          // console.log(this.imageUrl);
          axios.post('/user/update', this.editForm)
            .then(response => {
              if (response.data.code === 200) {
                this.$message.success('用户头像更新成功');
                this.activeNames = [];
                this.imageUrl = '';
                // 更新 Vuex store
                this.userData.photo = this.editForm.photo;
                this.$store.commit('setUserData', this.userData);
                this.srcList.push(this.userData.photo);
                this.loading = false;
              }
            })
            .catch(error => {
              this.$message.error('更新失败：' + error.message);
            });
        })
        .catch(error => {
          console.error('上传失败', error);
          this.loading = false;
        });
    },
    submitUsername() {
      this.$refs.usernameForm.validate((valid) => {
        if (valid) {
          this.updateUsername();
        }
      });
    },
    updateUsername() {
      // console.log(this.usernameForm);
      // 更新用户名信息
      this.editForm.username = this.usernameForm.username;
      axios.post('/user/upusername', this.editForm)
        .then(response => {
          if (response.data.code === 200) {
            this.$message.success('用户名更新成功');
            this.activeNames = [];
            this.usernameForm = {};
            // 更新 Vuex store
            this.userData.username = this.usernameForm.username;
            this.$store.commit('setUserData', this.userData);
          }

        })
        .catch(error => {
          this.$message.error('更新失败：' + error.message);
        });
    },
    submitPassword() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.updatePassword();
        }
      });
    },
    updatePassword() {
      console.log(this.passwordForm);
      this.editForm.password = this.passwordForm.newPassword;
      // 更新密码信息
      axios.post('/user/uppssword', this.editForm)
        .then(response => {
          if (response.data.code === 200) {
            this.$message.success('密码更新成功');
            this.activeNames = [];
            this.passwordForm = {};
            // 更新 Vuex store
            this.userData.password = this.passwordForm.password;
            this.$store.commit('setUserData', this.userData);
          }
        })
        .catch(error => {
          this.$message.error('更新失败：' + error.message);
        });
    }
  }
};
</script>

<style scoped>
/* 整体容器样式 */
.user-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  /* 限制最大宽度 */
  margin: auto;
}
.user-header{
  height: 360px;
}
.user-avatar {
  position:relative;
  padding: 10px;
}
.avatar-image {
  width: 250px;
  margin: 20px;
  z-index: 999;
  border-radius:5% ;
  border: 1px solid #181313;
}
.background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.2;
}
/* 用户名样式 */
.user-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 20px;
}

.edit-dialog {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  min-width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 2px dashed #d9d9d9;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.cropper {
  width: auto;
  height: 350px;
}
</style>