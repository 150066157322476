<template>
  <div class="register-container">
    <h1 style="display: block;margin: 0px auto;text-align: center;padding-bottom: 20px;">注册</h1>
    <div style="text-align: right;margin-bottom: 5px;">
      <el-link type="primary" @click="goToLogin">返回登录页面</el-link>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      label-position="left">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="ruleForm.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassword">
        <el-input type="password" v-model="ruleForm.checkPassword" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" style="width:100%;">申请注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  data() {
    var validateName = (rule, value, callback) => {
      const pattern = /^[a-zA-Z_][a-zA-Z0-9_-]*$/;
      if (value === '') {
        callback(new Error('请输入用户名'));
      } else if (!pattern.test(value)) {
        callback(new Error('只能包含字母、数字、下划线、减号，且不能以数字开头'));
      } else if (value.length < 6) {
        callback(new Error('用户名不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('用户名不能大于15位'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      const pattern = /^[a-zA-Z0-9_-]*$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!pattern.test(value)) {
        callback(new Error('密码只能包含字母、数字、下划线、减号'));
      } else if (value.length < 6) {
        callback(new Error('密码不能少于6位'));
      } else if (value.length > 15) {
        callback(new Error('密码不能大于15位'));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password: '',
        checkPassword: '',
        username: '',
        status: 1,
        createTime: ''
      },
      rules: {
        username: [
          { required: true, validator: validateName, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        // 获取当前的时间
        const currentTime = new Date();
        const year = currentTime.getFullYear();
        const month = currentTime.getMonth() + 1;
        const day = currentTime.getDate();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        const seconds = currentTime.getSeconds();

        // 确保月份、日期、小时、分钟和秒钟是两位数
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        const formattedTime = `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

        this.ruleForm.createtime = formattedTime;
        if (valid) {
          axios.post('/user/register', this.ruleForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.$message.success('注册成功');
              } else if (response.data.msg === '用户名已存在') {
                this.$message.error('用户名已存在');
              }
            })
            .catch((error) => {
              // console.error('注册失败', error);
              this.$message.error('注册失败');
            });
        } else {
          this.$message.error('请检查输入');
        }
      });
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.register-container {
  width: 300px;
  padding: 10px 30px;
  margin: 200px auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
/deep/.el-input__inner {
  background-color: #ffffff00 !important;
  border: 1px solid #000000;
}
</style>