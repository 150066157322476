<template>
  <el-card style="min-height: 90vh;">
    <!-- 走马灯 -->
    <el-carousel height="450px" :interval="carouselInterval" arrow="always" type="card" style="margin-top:20px; margin-bottom: 20px; ">
      <el-carousel-item v-for="(img, index) in carousel" :key="index">
        <el-image :src="img" style="width: 100%; height: 450px;"></el-image>
      </el-carousel-item>
    </el-carousel>
    <h2 style="margin-top: 40px; margin-bottom: 20px;">售票中心</h2>
    <hr/>
    <el-row :gutter="20" style="margin-top:20px; margin-bottom: 20px; ">
      <!-- 列表数据 -->
      <el-col :span="6" v-for="(item, index) in data" :key="index" class="item" @click.native="goToDetail(item)">
        <el-card style="height: 100%; cursor: pointer;">
          <el-image v-if="item.photo" :src="item.photo" style="width: 100%; height: 200px;"></el-image>
          <div style="padding: 5px;">
            <p style="margin: 10px;">景点名称：{{ item.attractionsname }}</p>
            <p style="margin: 10px;">价格：{{ item.attractionsprice }}</p>
            <p style="margin: 10px;">地址：{{ item.address }}</p>
            <p style="margin: 10px;">开放时间：{{ item.opentime }}</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 分页组件 -->
    <el-pagination
      @current-change="handlePageChange"
      :current-page="pageInfo.pageNum"
      :page-size="pageInfo.pageSize"
      layout="prev, pager, next"
      :total="pageInfo.total">
    </el-pagination>
  </el-card>
</template>

<script>
import { Image as ElImage } from 'element-ui';

export default {
  name: "TouristList",
  components: {
    [ElImage.name]: ElImage
  },
  data() {
    return {
      carouselInterval: 4000,
      data: [
      ],
      carousel: [
        require('@/assets/img/nature-4522878_640.jpg'),
        require('@/assets/img/sea-164989_640.jpg'),
        require('@/assets/img/sunset-7865844_640.webp'),
        require('@/assets/img/lake-71208_640.jpg'),
      ],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 12,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get('/attraction/selectPage', {
        params: this.pageInfo
      })
      .then((response) => {
        // 处理成功情况
        // console.log(response.data.list);
        const list = response.data.list;
    this.data = list.map(item => {
      return {
        ...item,
        opentime: item.starttime + ' 至 ' + item.endtime
      };
    });
    this.pageInfo.total = response.data.total;
      })
      .catch((error) => {
        this.$elMessage.error("获取数据失败");
        // console.error("Error fetching data: ", error);
      });
    },
    handlePageChange(newPage) {
      this.pageInfo.pageNum = newPage;
      this.fetchData();
    },
    goToDetail(item) {
      if (item && item.attractionsid) {
        // 使用编程式导航跳转，并传递item的id
        this.$router.push({ name: 'AttractionDetail', params: { attractionsid: item.attractionsid } });
        // console.log("Navigating to detail with id:", item.attractionsid);
      } else {
        // this.$elMessage.error("无法获取景点ID");
        console.error("Item or item.attractionsid is undefined");
      }
    }
  },
};
</script>

<style>
.item {
  height: 450px;
  margin-bottom: 20px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.item:hover {
  transform: translateY(-5px);
}
</style>